import Rollbar from "rollbar";

const rollbarConfig = new Rollbar({
  accessToken: "7127acfa0d4c4c34aeb6e0492b0d0c87",
  captureUncaught: true,
  captureUnhandledRejections: true,
  checkIgnore: function (isUncaught, args, payload) {
    var browser = payload.client.javascript.browser;

    if (browser.match(/(MSIE |Trident.*rv[ :])([0-9]+)/)) {
      return (true);
    };

    if (payload.body.trace.exception.description === "Script error." && payload.body.trace.exception.message === "Script error.") {
      return true;
    };

    // Ignore JS error caused by interaction with Ad blockers.See Assembla #15046 for more details.
    if (payload.body.trace.exception.description === "Cannot redefine property: googletag" && payload.body.trace.exception.message === "Cannot redefine property: googletag") {
      return true;
    };

    // Ignore errors originating from Chrome extensions
    if (payload?.notifier?.diagnostic?.raw_error?.stack?.indexOf('chrome-extension://') > -1) {
      return true;
    }

    if (payload.body.trace.exception.message === "Can't find variable: _AutofillCallbackHandler" && /iPhone|iPad|iPod/i.test(browser)) {
      return true;
    }

    return false;
  },
  payload: {
    environment: process.env.RAILS_ENV,
    client: {
      javascript: {
        code_version: '1.0.0',
      }
    },
  }
});